import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Form as BaseForm, Formik, FormikHelpers, FormikProps } from "formik";
import styled from "styled-components";

import { API } from "@api";
import { IFamily } from "@api/assets";
import {
  Accordion,
  Button,
  ErrorMessage,
  FileField,
  Modal,
  NumberField,
  SelectField,
  SwitchField,
  Tabs,
  TextAreaField,
  TextField,
} from "@components";
import { ImageSlider } from "@components/forms/CreateLotForm/ImageSlider";
import {
  MAX_RESERVE_PRICE_VALUE,
  MIN_LOT_VALUE,
  MIN_RESERVE_PRICE_VALUE,
  ONLINE_GENERAL_SCHEMA,
} from "@components/forms/CreateLotForm/validationSchema";
import { useAssetsAndLots } from "@hooks";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { IAuction, ICountry, IError, Nullable } from "@utils";
import { ONLINE_INITIAL_VALUES } from "./initialValues";

type TCreateOnlineLotFormValues = typeof ONLINE_INITIAL_VALUES;

enum LotTabs {
  GeneralData,
  LotDetails,
}

type Props = {
  onClose: () => void;
  onRetry: () => void;
  error: Nullable<IError>;
  loading?: boolean;
  onSubmit: (
    values: TCreateOnlineLotFormValues,
    helpers: FormikHelpers<TCreateOnlineLotFormValues>
  ) => Promise<void>;
  auction: IAuction;
};

const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.tripleMargin};
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const GeneralInfo = styled.div`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primaryBlue};
  display: flex;
  margin-bottom: 10px;
  padding: 8px 28px;
  text-transform: uppercase;

  & > div {
    flex-grow: 1;
    text-align: center;
  }

  & > div:nth-child(2) {
    border-left: 1px solid ${({ theme }) => theme.casalColor};
    border-right: 1px solid ${({ theme }) => theme.casalColor};
  }
`;
const InfoHeading = styled.div`
  color: ${({ theme }) => theme.grey};
`;
const InfoValue = styled.div`
  color: ${({ theme }) => theme.white};
`;
const ImageGallery = styled.div`
  display: flex;
  padding: 16px 0;
  max-width: 300px;
  flex-wrap: wrap;
`;
const ImageItem = styled.img`
  width: 50px;
  margin: 5px;
`;
const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  padding-bottom: 14px;

  span {
    min-width: 280px;
  }
  div {
    margin-top: 0;
  }
`;

export const CreateOnlineLotForm: FC<Props> = ({
  loading,
  error,
  onRetry,
  onSubmit,
  onClose,
  auction,
}) => {
  const [families, setFamilies] = useState<IFamily[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(LotTabs.GeneralData);
  const [uploadedPhotos, setUploadedPhotos] = useState<any>({});
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const formRef = useRef<FormikProps<TCreateOnlineLotFormValues>>(null);
  const tabsRef = useRef<any>(null);

  const { assets, selectedAssets } = useAssetsAndLots();

  const filteredAssets = assets.filter((asset) =>
    selectedAssets.some((item) => item === asset.id)
  );

  const { t } = useI18n();

  useEffect(() => {
    async function getFilters() {
      try {
        const families = await API.assets.families();
        setFamilies(families);
      } catch (_) {
        setFamilies([]);
      }
    }
    async function getCountries() {
      try {
        const countries = await API.users.countries();
        setCountries(countries);
      } catch (_) {
        setCountries([]);
      }
    }

    getFilters();
    getCountries();
  }, []);

  useEffect(() => {
    createUrl(uploadedPhotos);
  }, [uploadedPhotos]);

  const handleSubmit = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(true);
      await onSubmit(values, helpers);
      helpers.setSubmitting(false);
    },
    [onSubmit]
  );

  const createUrl = (files: any): void => {
    let url: any = [];
    for (let file in files) {
      typeof files[file] === "object" &&
        url.push(URL.createObjectURL(files[file]));
    }
    setImageUrls(url);
  };

  return (
    <Formik<TCreateOnlineLotFormValues>
      initialValues={ONLINE_INITIAL_VALUES}
      validationSchema={ONLINE_GENERAL_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={formRef}
    >
      {({
        isValid,
        handleSubmit,
        values,
        touched,
        setValues,
        setFieldValue,
      }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <Modal
            width="50%"
            title={t("lotForm.title")}
            actions={
              <>
                <Button
                  onClick={() => {
                    setValues(ONLINE_INITIAL_VALUES);
                    setUploadedPhotos({});
                    setImageUrls([]);
                    onClose();
                  }}
                  bordered
                >
                  {t<string>("lotForm.cancel")}
                </Button>
                {activeTabIndex === 0 && (
                  <Button
                    type="button"
                    disabled={!Object.values(touched).length || !isValid}
                    onClick={() => tabsRef.current?.children[1].click()}
                    loading={loading}
                  >
                    {t<string>("lotForm.addDetails")}
                  </Button>
                )}
                {!error && activeTabIndex !== 0 && (
                  <Button
                    type="submit"
                    disabled={!Object.values(touched).length || !isValid}
                    onClick={() => null}
                    loading={loading}
                  >
                    {t<string>("lotForm.submit")}
                  </Button>
                )}
              </>
            }
            onClose={onClose}
          >
            {error ? (
              <ErrorMessage error={error} onRetry={onRetry} />
            ) : (
              <>
                <Tabs
                  tabsRef={tabsRef}
                  width="100%"
                  activeTabIndex={activeTabIndex}
                  onTabClick={(index) => {
                    setActiveTabIndex(index);
                  }}
                  headers={[
                    t("lotForm.tabs.general"),
                    {
                      label: t("lotForm.tabs.details"),
                      disabled: !Object.values(touched).length || !isValid,
                    },
                  ]}
                >
                  <div>
                    <GeneralInfo>
                      <div>
                        <InfoHeading>
                          {t("lotForm.detailsBlock.totalAssets")}
                        </InfoHeading>
                        <InfoValue>{selectedAssets.length}</InfoValue>
                      </div>
                      <div>
                        <InfoHeading>
                          {t("lotForm.detailsBlock.totalAqcValue")}
                        </InfoHeading>
                        <InfoValue>
                          {filteredAssets.reduce(
                            (a, b) => a + +b.acquisition_value,
                            0
                          )}
                          $
                        </InfoValue>
                      </div>
                      <div>
                        <InfoHeading>
                          {t("lotForm.detailsBlock.totalMarketValue")}
                        </InfoHeading>
                        <InfoValue>
                          {filteredAssets.reduce(
                            (a, b) => a + +b.market_value,
                            0
                          )}
                          $
                        </InfoValue>
                      </div>
                    </GeneralInfo>
                    <Row>
                      <NumberField
                        name="lot"
                        label={t("lotForm.lot.label")}
                        placeholder={t("lotForm.lot.placeholder")}
                        min={MIN_LOT_VALUE}
                        required
                      />
                      <TextField
                        name="name"
                        label={t("lotForm.name.label")}
                        placeholder={t("lotForm.name.placeholder")}
                        required
                      />
                    </Row>

                    <Row>
                      <SelectField
                        name="assetFamily"
                        label={t("lotForm.assetFamily.label")}
                        placeholder={t("lotForm.assetFamily.placeholder")}
                        onChange={(event) => {
                          setFieldValue("assetFamily", event.target.value);
                          if (
                            event.target.value === "Light Vehicle" ||
                            event.target.value === "Trucks"
                          ) {
                            setFieldValue("brand", filteredAssets[0].brand);
                            setFieldValue("model", filteredAssets[0].model);
                            setFieldValue(
                              "country",
                              filteredAssets[0].city?.country.geoname_id
                            );
                            setFieldValue(
                              "yearOfMake",
                              filteredAssets[0].year_of_manufacture
                            );
                            setFieldValue(
                              "mileage",
                              filteredAssets[0].mileage_km
                            );
                          }
                        }}
                        options={families.map((family) => ({
                          label: family.label,
                          value: family.value,
                        }))}
                        required
                      />
                      <NumberField
                        name="startPrice"
                        label={t("lotForm.startPrice.label", {
                          currency: auction.currency.code,
                        })}
                        placeholder={t("lotForm.startPrice.placeholder", {
                          currency: auction.currency.code,
                        })}
                        min={MIN_LOT_VALUE}
                        required
                      />
                    </Row>
                    <Row>
                      <NumberField
                        name="minStep"
                        label={t("lotForm.minStep.label", {
                          currency: auction.currency.code,
                        })}
                        placeholder={t("lotForm.minStep.placeholder", {
                          currency: auction.currency.code,
                        })}
                        min={MIN_RESERVE_PRICE_VALUE}
                        max={MAX_RESERVE_PRICE_VALUE}
                        required
                      />
                      <NumberField
                        name="reservePrice"
                        label={t("lotForm.reservePrice.label", {
                          currency: auction.currency.code,
                        })}
                        placeholder={t("lotForm.reservePrice.placeholder", {
                          currency: auction.currency.code,
                        })}
                        min={MIN_RESERVE_PRICE_VALUE}
                        max={MAX_RESERVE_PRICE_VALUE}
                        required
                      />
                    </Row>
                    <Row>
                      <SwitchContainer>
                        <span>{t("lotForm.customDuties")}</span>
                        <SwitchField name="customClearance" />
                      </SwitchContainer>
                    </Row>
                  </div>
                  <div>
                    <GeneralInfo>
                      <div>
                        <InfoHeading>
                          {t("lotForm.detailsBlock.lotNumber") + values.lot}
                        </InfoHeading>
                      </div>
                      <div>
                        <InfoHeading>{values.name}</InfoHeading>
                      </div>
                      <div>
                        <InfoHeading>
                          {t("lotForm.detailsBlock.startPrice") +
                            ` ${values.startPrice ? values.startPrice : "-"} (${
                              auction.currency.code
                            })`}
                        </InfoHeading>
                      </div>
                    </GeneralInfo>
                    {(values.assetFamily === "Light Vehicle" ||
                      values.assetFamily === "Trucks") && (
                      <Accordion title={t("lotForm.accordionTitles.details")}>
                        <Row>
                          <TextField
                            name="brand"
                            label={t("lotForm.brand.label")}
                            placeholder={t("lotForm.brand.placeholder")}
                          />
                          <TextField
                            name="model"
                            label={t("lotForm.model.label")}
                            placeholder={t("lotForm.model.placeholder")}
                          />
                        </Row>
                        <Row>
                          <SelectField
                            name="country"
                            label={t("lotForm.country.label")}
                            placeholder={t("lotForm.country.placeholder")}
                            options={countries.map((country) => ({
                              label: country.name,
                              value: country.geoname_id,
                            }))}
                          />
                          <SelectField
                            name="driverSide"
                            label={t("lotForm.driverSide.label")}
                            placeholder={t("lotForm.driverSide.placeholder")}
                            options={[
                              {
                                label: "Left",
                                value: "left",
                              },
                              {
                                label: "Right",
                                value: "right",
                              },
                            ]}
                          />
                        </Row>
                        <Row>
                          <NumberField
                            name="yearOfMake"
                            label={t("lotForm.yearOfMake.label")}
                            placeholder={t("lotForm.yearOfMake.placeholder")}
                          />
                          <NumberField
                            name="mileage"
                            label={t("lotForm.mileage.label")}
                            placeholder={t("lotForm.mileage.placeholder")}
                          />
                        </Row>
                        <Row>
                          <SelectField
                            name="engine"
                            label={t("lotForm.engine.label")}
                            placeholder={t("lotForm.engine.placeholder")}
                            options={[
                              {
                                label: "Diesel",
                                value: "diesel",
                              },
                              {
                                label: "Gasoline",
                                value: "gasoline",
                              },
                            ]}
                          />
                          <SelectField
                            name="transmission"
                            label={t("lotForm.transmission.label")}
                            placeholder={t("lotForm.transmission.placeholder")}
                            options={[
                              {
                                label: "Manual",
                                value: "manual",
                              },
                              {
                                label: "Automatic",
                                value: "automatic",
                              },
                              {
                                label: "Semi Automatic",
                                value: "semi_automatic",
                              },
                            ]}
                          />
                        </Row>
                        <Row>
                          <FileField
                            name="inspectionReport"
                            label={t("lotForm.inspectionReport.label")}
                            placeholder={t(
                              "lotForm.inspectionReport.placeholder"
                            )}
                          />
                        </Row>
                      </Accordion>
                    )}
                    <Accordion title={t("lotForm.accordionTitles.medias")}>
                      <Row>
                        <div>
                          <FileField
                            name="photo"
                            accept="image/*"
                            label={t("lotForm.photo.label")}
                            placeholder={t("lotForm.photo.placeholder")}
                            onChange={(event) => {
                              setFieldValue("photo", event.currentTarget.files);
                              setUploadedPhotos(event.currentTarget.files);
                            }}
                            multiple
                          />
                          {imageUrls.length > 0 && (
                            <ImageGallery>
                              {imageUrls.map((item) => (
                                <ImageItem key={item} src={item} />
                              ))}
                            </ImageGallery>
                          )}
                          <TextField
                            name="video"
                            accept="video/*"
                            label={t("lotForm.video.label")}
                            placeholder={t("lotForm.video.placeholder")}
                          />
                        </div>
                        <div>
                          {imageUrls.length > 0 && (
                            <ImageSlider urls={imageUrls} />
                          )}
                        </div>
                      </Row>
                    </Accordion>
                    <Accordion title={t("lotForm.accordionTitles.description")}>
                      <TextAreaField
                        name="description"
                        label={t("lotForm.description.label")}
                        placeholder={t("lotForm.description.placeholder")}
                      />
                    </Accordion>
                  </div>
                </Tabs>
              </>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
